import { AvailabilityForm } from '@web/components/lego/blocks/AvailabilityForm'
import { BasicFacilityDetailsForm } from '@web/components/lego/blocks/BasicFacilityDetailsForm'
import { ServiceCostingForm, SpecialityForm } from '@web/components/lego/blocks/ServiceCostingForm'
import { BasicDoctorDetailsForm } from '@web/components/lego/blocks/BasicDoctorDetailsForm'
import {
  DropdownSelectForm,
  InviteFacilityManagerForm,
  InviteUserForm,
  SearchUserForm,
} from '@web/components/lego/blocks/InviteUserForm'

export const LegoBlocks = {
  AvailabilityForm,
  BasicFacilityDetailsForm,
  ServiceCostingForm,
  BasicDoctorDetailsForm,
  InviteUserForm,
  SpecialityForm,
  SearchUserForm,
  DropdownSelectForm,
  InviteFacilityManagerForm,
}
