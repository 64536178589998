import { LegoBuilder } from '@ropha/builder';
import { Insurer } from '@web/data/models/Insurer';
import { UserActivity } from '@web/data/models/User';
import { DisplayUtils } from '@web/utils/display.utils';
import {
  AppointmentEntity,
  AuthenticatedUserEntity,
  FacilityEntity,
  ImportUserDto,
  OfferingCostEntity,
  PaymentEntity,
  User
} from '@web/gen/api';

export const FACILITY_TABLE_RENDERER = (row: FacilityEntity) => [
  LegoBuilder.Text()
    .content(row.registeredDomain || row.slug)
    .variant('text-bold')
    .build(),

  LegoBuilder.ListItem()
    .primaryText(row.name)
    .secondaryText(row.address)
    .avatar(row.logo)
    .build(),

  LegoBuilder.StatusChip()
    .label(row.status.toString())
    .build(),

  LegoBuilder.ListItem()
    .primaryText(new Date(row.createdAt).toDateString())
    .secondaryText(new Date(row.createdAt).toLocaleTimeString())
    .build(),

  LegoBuilder.ProgressBar()
    .value(100)
    .build()
];

export const INSURER_TABLE_RENDERER = (row: Insurer) => [
  LegoBuilder.Text()
    .content(row.slug)
    .variant('caption')
    .build(),

  LegoBuilder.ListItem()
    .primaryText(row.name)
    .secondaryText(row.address)
    .avatar(row.avatar)
    .build(),

  LegoBuilder.StatusChip()
    .label(row.status)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(row.createdAt.toDateString())
    .secondaryText(row.createdAt.toLocaleTimeString())
    .build(),

  LegoBuilder.ProgressBar()
    .value(row.progress)
    .build()
];

export const ADMINISTRATOR_TABLE_RENDERER = (row: User) => [
  LegoBuilder.StatusChip()
    .label(row.id)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.fullName(row))
    .secondaryText(row.email)
    .avatar(row.profilePicture)
    .build(),

  LegoBuilder.StatusChip()
    .label(row.status.toString())
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.createdAt))
    .secondaryText(DisplayUtils.toTimeString(row.createdAt))
    .build()
];

export const ACTIVITY_TABLE_RENDERER = (record: UserActivity) => [
  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.truncated(record.name, 20))
    .secondaryText(DisplayUtils.truncated(record.description, 40))
    .build(),

  ...record.isOnline ?
    [LegoBuilder.Dot().color('success').build()] :
    [
      LegoBuilder.Text()
        .variant('caption')
        .content(DisplayUtils.toHumanDate(record.lastActivity))
        .build()
    ]
];

export const DOCTOR_TABLE_RENDERER = (row: User) => [
  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.fullName(row))
    .secondaryText(row.email)
    .avatar(row.profilePicture)
    .build(),

  LegoBuilder.Text()
    .variant('caption')
    .content(row.address)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.createdAt))
    .secondaryText(DisplayUtils.toTimeString(row.createdAt))
    .build(),

  LegoBuilder.StatusChip()
    .label(row.status.toString())
    .build()
];

export const PATIENT_TABLE_RENDERER = (row: User) => [
  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.fullName(row))
    .secondaryText(row.email)
    .avatar(row.profilePicture)
    .build(),

  LegoBuilder.Text()
    .variant('paragraph')
    .content(row.email)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(row.createdAt? DisplayUtils.toDateString(row.createdAt): '')
    .secondaryText( row.createdAt? DisplayUtils.toTimeString(row.createdAt):'')
    .build()
];

export const STATEMENTS_TABLE_RENDERER = (row: PaymentEntity) => [
  LegoBuilder.Text()
    .variant('text-bold')
    .content(DisplayUtils.truncated(row.id, 8))
    .build(),

  LegoBuilder.Text()
    .variant('paragraph')
    .content(DisplayUtils.toMoney(row.amount))
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.createdAt))
    .secondaryText(DisplayUtils.toHumanDateFromString(row.createdAt))
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.narration!))
    .secondaryText(DisplayUtils.toHumanDateFromString(row.narration!))
    .build(),

  ...row.status === 'COMPLETED' ?
    [
      LegoBuilder.ListItem()
        .primaryText(DisplayUtils.toDateString(row.updatedAt!))
        .secondaryText(DisplayUtils.toHumanDateFromString(row.updatedAt!))
        .build()
    ] :
    [
      LegoBuilder.Text()
        .variant('paragraph')
        .content('-')
        .build()
    ],

  LegoBuilder.StatusChip()
    .label(DisplayUtils.enumToHuman(row.status === 'COMPLETED' ? 'PAID' : 'UNPAID'))
    .build()
];

export const OFFERING_TABLE_RENDERER = (row: OfferingCostEntity) => [
  LegoBuilder.ListItem()
    .primaryText(row.name)
    .secondaryText(DisplayUtils.truncated(row.description, 50))
    .build(),

  LegoBuilder.StatusChip()
    .label(DisplayUtils.toMoney(row.cost))
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.createdAt))
    .secondaryText(DisplayUtils.toTimeString(row.createdAt))
    .build()
];

export const APPOINTMENT_TABLE_RENDERER = (row: AppointmentEntity) => [
  LegoBuilder.ListItem()
    .primaryText(row.narrative)
    .secondaryText(DisplayUtils.truncated(row.id, 50))
    .build(),

  LegoBuilder.StatusChip()
    .label(row.mode)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.startsAt))
    .secondaryText(DisplayUtils.toTimeString(row.startsAt))
    .build(),

  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.toDateString(row.createdAt))
    .secondaryText(DisplayUtils.toTimeString(row.createdAt))
    .build(),

  LegoBuilder.Text()
    .content(DisplayUtils.durationBetween(row.startsAt, row.completesAt))
    .build(),

  LegoBuilder.StatusChip()
    .label(DisplayUtils.enumToHuman(row.status))
    .build()
];

export const INVOICE_TABLE_RENDERER = (row: PaymentEntity) => [
  LegoBuilder.Text()
    .variant('text-bold')
    .content(DisplayUtils.truncated(row.id, 12))
    .build(),

  LegoBuilder.Text()
    .variant('text')
    .content(DisplayUtils.toDateString(row.createdAt))
    .build(),

  LegoBuilder.Text()
    .variant('text-bold')
    .content(DisplayUtils.toMoney(row.amount))
    .build()
];

export const USER_IMPORT_TABLE_RENDERER = (userType: AuthenticatedUserEntity['userType']) => (row: ImportUserDto & {
  status?: string
}) => [
  LegoBuilder.ListItem()
    .primaryText(DisplayUtils.fullName(row))
    .secondaryText(row.email)
    .build(),

  ...userType === 'PATIENT' ? [
    LegoBuilder.Text()
      .variant('paragraph-bold')
      .content(row.insuranceNumber)
      .build()
  ] : [],

  LegoBuilder.ListItem()
    .primaryText(row.phoneNumber)
    .secondaryText(row.gender)
    .build(),

  LegoBuilder.ListItem()
    .primaryText(row.createdAt ? DisplayUtils.toDateString(DisplayUtils.fromDDMMYYYY(row.createdAt)):'')
    .secondaryText(row.createdAt ? DisplayUtils.toTimeString(DisplayUtils.fromDDMMYYYY(row.createdAt)):'')
    .build(),

  ...userType === 'PATIENT' ? [
    LegoBuilder.ListItem()
      .primaryText(DisplayUtils.toDateString(DisplayUtils.fromDDMMYYYY(row.expiresAt)))
      .secondaryText(DisplayUtils.toTimeString(DisplayUtils.fromDDMMYYYY(row.expiresAt)))
      .build()
  ] : [],

  LegoBuilder.StatusChip()
    .label(DisplayUtils.enumToHuman(row.status || 'PENDING'))
    .build()
];
