import { useAuth0 } from '@auth0/auth0-react';
import Logo from '@web/components/atoms/Logo';
import { Navigate } from 'react-router-dom';

export default function CallbackPage() {
  const { error, isAuthenticated, logout } = useAuth0();

  if (!error && isAuthenticated) return <Navigate to={'/'} />;

  return (
    <div className="account-pages my-5 pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center mb-4 mt-3">
                  <a href="/">
                <span><Logo /></span>
                  </a>
                </div>
                <div className="mt-4 pt-3">
                  <h3 className="expired-title text-center mb-4 mt-3 font-weight-normal">
                    Oops! Something Went Wrong
                  </h3>
                  <p className="text-muted mt-3">
                    It's looking like you may have taken a wrong turn. Don't
                    worry... it happens to the best of us
                  </p>
                  <p className="font-weight-bold text-danger mt-3">
                    {error?.message}
                  </p>
                </div>
                <div className="mb-3 mt-4 text-center">
                  <a href="/" className="btn btn-primary btn-block">
                    Back to Home
                  </a>
                </div>
                <div className="mb-3 mt-4 text-center">
                  <a href="#" onClick={() => logout({ logoutParams: { federated: true } })}
                     className="btn btn-secondary btn-block">
                    Logout
                  </a>
                </div>
              </div>
              {/* end card-body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </div>
  );
}
