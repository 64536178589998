import { UserType } from '@prisma/client'
import { TenantTheme } from '@web/contexts/TenantThemeContext'
import navigation from '@web/data/menus'
import { AuthenticatedUserEntity } from '@web/gen/api'
import { api } from '@web/hooks/useApi'
import { call } from '@web/utils/api.utils'
import { ASSUMED_ROLE_STORAGE_KEY } from '@web/utils/constants'
import { action, makeObservable, observable } from 'mobx'

export class UserViewModel {
  @observable user?: AuthenticatedUserEntity

  constructor() {
    makeObservable(this)
  }

  get tenantId() {
    return this.user?.tenant?.id
  }

  get hasTenant() {
    return !!this.tenantId
  }

  get tenantTheme(): TenantTheme | undefined {
    if (this.user?.tenant) {
      return {
        logoBanner: this.user.tenant.logo,
        logoSquare: this.user.tenant.favicon,
        color: this.user.tenant.color,
        title: this.user.tenant.name,
      }
    }
  }

  get userType(): AuthenticatedUserEntity['userType'] {
    return this.user?.userType || 'UNKNOWN'
  }

  get assumedRole(): AuthenticatedUserEntity['userType'] {
    return this.user?.assumedRole || this.userType
  }

  get profile() {
    return this.user?.profile
  }

  get facilities() {
    return this.user?.facilities || []
  }

  get compositeRoles() {
    return this.user?.compositeRoles || []
  }

  get canDoBulkUpload() {
    if (!this.user) return false
    return (['INSURANCE_MANAGER', 'FACILITY_MANAGER'] as UserType[]).includes(this.user.userType)
  }

  @action
  async loadUser() {
    if (this.user) return
    this.user = await call(api.users.me)
    // if(this.user.userType === 'ADMINISTRATOR'){
    //   window.location.href = 'https://admin.ropha.io'
    // }else {
    //   window.location.href = this.user.tenant!.id!;
    // }

  }

  @action
  assumeRole(role: AuthenticatedUserEntity['userType']) {
    localStorage.setItem(ASSUMED_ROLE_STORAGE_KEY, role.toString())
    window.location.reload()
  }

  async getAvailability() {
    if (this.tenantId) {
      return call(api.availabilities.facility, this.tenantId)
    } else if (this.user) {
      return call(api.availabilities.doctor, this.user.id)
    }
    return []
  }

  async getAppointments() {
    return call(api.appointments.appointments)
  }

  async getServiceCosts() {
    if (this.hasTenant) return call(api.offerings.facility, this.tenantId!, { type: 'SERVICE' })
    else if (this.user) return call(api.offerings.doctor, this.user.id, { type: 'SERVICE' })
    return []
  }

  async getSpecialityCosts() {
    if (this.hasTenant) return call(api.offerings.facility, this.tenantId!, { type: 'SPECIALITY' })
    if (this.user) return call(api.offerings.doctor, this.user.id, { type: 'SPECIALITY' })
    return []
  }

  async addOfferings(data: { offeringId: string; cost: number }[]) {
    const doctorId = this.hasTenant ? undefined : this.user?.id
    return call(api.offerings.create, {
      offerings: data.map(d => ({ ...d, facilityId: this.tenantId, doctorId })),
    })
  }

  async availabilities() {
    if (this.hasTenant) return call(api.availabilities.facility, this.tenantId!)
    if (this.user) return call(api.availabilities.doctor, this.user.id)
    return []
  }

  get speedDials() {
    if (!this.user) return []
    return navigation.items
      .flatMap((i: any) => i.children)
      .filter(i => i.fab)
      .map(i => {
        if (this.userType === 'INSURANCE_MANAGER') {
          return {
            ...i,
            title: String(i.title).replace('Patients', 'Members'),
          }
        }
        return i
      })
      .filter((c: any) => !c.resource || this.hasPermission(`create:${c.resource}`))
  }

  get navigation() {
    if (!this.user) return []
    return navigation.items
      .map(({ children, ...props }) => ({
        ...props,
        children: children
          .filter((c: any) => !c.resource || this.hasPermission(`read:${c.resource}`))
          .map(i => {
            if (this.userType === 'INSURANCE_MANAGER') {
              return {
                ...i,
                title: String(i.title).replace('Patients', 'Members'),
              }
            }
            return i
          })
          .map(c => {
            if (c.id === 'facility-manager' && this.userType === 'INSURANCE_MANAGER') {
              return {
                ...c,
                title: 'Invited Facilities',
              }
            }
            return c
          }),
      }))
      .filter(i => i.children.length > 0)
  }

  hasPermission(permission: string) {
    return this.user?.permissions.includes(permission)
  }

  hasOwnedPermission(permission: string) {
    return this.hasPermission(permission) && this.isInOwnedContext()
  }

  assumesRole(role: AuthenticatedUserEntity['userType']) {
    return this.assumedRole === role
  }

  assumesOneOfRoles(role: AuthenticatedUserEntity['userType'][]) {
    return role.includes(this.assumedRole)
  }

  private isInOwnedContext() {
    return (
      this.assumesRole('FACILITY_MANAGER') || (this.assumedRole === 'DOCTOR' && !this.hasTenant)
    )
  }
}
