import { FacilityEntity } from '@web/gen/api';
import { api } from '@web/hooks/useApi';
import { call } from '@web/utils/api.utils';
import { action, makeObservable, observable } from 'mobx';


export class FacilityViewModel {
  constructor() {
    makeObservable(this)
  }

  @observable facilities :FacilityEntity[] = []

  @action
  async loadAllFacilities(){
    this.facilities = await call(api.facilities.existing);
  }

  async searchFacilities(input: string) {
    if (!this.facilities?.length) await this.loadAllFacilities();
    if (input.length && this.facilities?.length) {
      return this.facilities.filter(u =>
        u.name.toLowerCase().includes(input.toLowerCase()) ||
        u.slug.toLowerCase().includes(input.toLowerCase())
      );
    }
    return this.facilities;
  }
}
