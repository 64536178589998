import { FacilityViewModel } from '@web/store/FacilityViewModel';
import { MenuViewModel } from '@web/store/MenuViewModel';
import { NotificationViewModel } from '@web/store/NotificationViewModel';
import { OfferingsViewModel } from '@web/store/OfferingsViewModel';
import { UserViewModel } from '@web/store/UserViewModel';
import { DoctorsViewModel } from './DoctorsViewModel';

export class Mvvm {
  notification: NotificationViewModel;
  offering: OfferingsViewModel;
  user: UserViewModel;
  menu: MenuViewModel;
  facility: FacilityViewModel
  doctors:DoctorsViewModel;

  constructor() {
    this.notification = new NotificationViewModel();
    this.offering = new OfferingsViewModel();
    this.user = new UserViewModel();
    this.menu = new MenuViewModel();
    this.facility = new FacilityViewModel();
    this.doctors = new DoctorsViewModel()
  }
}
