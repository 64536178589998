import SimpleBar from '@web/components/atoms/SimpleBar';
import NavCard from '@web/components/molecules/Drawer/DrawerContent/NavCard';
import Navigation from './Navigation';

function DrawerContent() {
  return (
    <SimpleBar sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column' } }}>
      <NavCard />
      <Navigation />
    </SimpleBar>
  );
}

export default DrawerContent;
