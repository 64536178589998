import { Box, BoxProps, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';


interface DrawerHeaderStyledProps extends BoxProps {
  open?: boolean;
}

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })
  < DrawerHeaderStyledProps > (
    ({ theme, open }) => ({
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: open ? 'flex-start' : 'center',
      paddingLeft: theme.spacing(open ? 3 : 0)
    })
  );

const DrawerHeader = ({ open }: { open?: boolean }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <Logo /> */}
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
