import { LegoBuilder } from '@ropha/builder'
import { GENERIC_DROPDOWN, USERS_DROPDOWN } from '@web/utils/dropdown.renderers'

export const InviteUserForm = LegoBuilder.InputGroup()
  .inputs({
    firstName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 }).label('First Name').build(),
    lastName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 }).label('Last Name').build(),
    email: LegoBuilder.TextInput().grid({ xs: 12, sm: 7 }).label('Email Address').build(),
    phoneNumber: LegoBuilder.TextInput().grid({ xs: 12, sm: 5 }).label('Phone Number').build(),
  })
  .build()

export const InviteFacilityManagerForm = LegoBuilder.InputGroup()
  .inputs({
    companyName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Company Name').build(),
    firstName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('First Name').build(),
    lastName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Last Name').build(),
    email: LegoBuilder.TextInput().grid({ xs: 12, sm: 7 })
      .label('Email Address').build(),
    phoneNumber: LegoBuilder.TextInput().grid({ xs: 12, sm: 5 })
      .label('Phone Number').build(),
  })
  .build();

export const SearchUserForm = (label: string,  getOptions: (_: string) => Promise<any>) =>
  LegoBuilder.InputArray()
    .input(
      LegoBuilder.InputGroup()
        .inputs({
          user: LegoBuilder.SelectInput().grid({ xs: 8, md: 9 })
            .label(label)
            .renderer(USERS_DROPDOWN)
            .getOptions(getOptions)
            .getLabel((value: any) => value.name)
            .build(),
        }).build()
    ).defaultValue(() => ({user:null}) )
    .build();

export const DropdownSelectForm = (
  label: string,
  getOptions: (_: string) => Promise<{title:string, value:string}[]>
) =>
  LegoBuilder.InputArray()
    .label(label)
    .input(
      LegoBuilder.InputGroup()
        .inputs({
          offering: LegoBuilder.SelectInput()
            .grid({ xs: 8, md: 9 })
            .label('User Type')
            .renderer(GENERIC_DROPDOWN)
            .getOptions(getOptions)
            .getLabel((value:{title:string, value:string}) => value.title)
            .build(),
        })
        .build()
    )
    .defaultValue(() => ({ offering: null, cost: '1.00' }))
    .build();
