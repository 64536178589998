import { lazy } from 'react';

import Loadable from '@web/components/Loadable';
import { AuthGuard } from '@web/guards/AuthGuard';
import MainLayout from '@web/layouts/MainLayout';
import MinimalLayout from '@web/layouts/MinimalLayout';
import { Navigate } from 'react-router-dom';

const SamplePage = Loadable(lazy(() => import('@web/pages/SamplePage')));
const Dashboard = Loadable(lazy(() => import('@web/pages/dashboard/DefaultDashboard')));
const Facilities = Loadable(lazy(() => import('@web/pages/onboarding/FacilitiesPage')));
const Insurers = Loadable(lazy(() => import('@web/pages/onboarding/InsurersPage')));
const Administrators = Loadable(lazy(() => import('@web/pages/users/Administrators')));
const FacilityManagers = Loadable(lazy(() => import('@web/pages/users/FacilityManagers')));
const InsuranceManagers = Loadable(lazy(() => import('@web/pages/users/InsuranceManagers')));
const MedicalDirectors = Loadable(lazy(() => import('@web/pages/users/MedicalDirectors')));
const Doctors = Loadable(lazy(() => import('@web/pages/users/Doctors')));
const Patients = Loadable(lazy(() => import('@web/pages/users/Patients')));
const Appointments = Loadable(lazy(() => import('@web/pages/medicals/Appointments')));
const Services = Loadable(lazy(() => import('@web/pages/activities/Services')));
const Specialities = Loadable(lazy(() => import('@web/pages/activities/Specialities')));
const Statements = Loadable(lazy(() => import('@web/pages/finances/Statements')));
const Claims = Loadable(lazy(() => import('@web/pages/finances/Claims')));
const Reconciliations = Loadable(lazy(() => import('@web/pages/finances/Reconciliations')));
const Appointment = Loadable(lazy(() => import('@web/pages/medicals/Appointment')));

const OnboardingWizard = Loadable(lazy(() => import('@web/pages/wizards/OnboardingWizard')));
const NewFacilityWizard = Loadable(lazy(() => import('@web/pages/wizards/NewFacilityWizard')));
const NewDoctorWizard = Loadable(lazy(() => import('@web/pages/wizards/NewDoctorWizard')));
const NewPatientWizard = Loadable(lazy(() => import('@web/pages/wizards/NewPatientWizard')));
const NewFacilityManagerWizard = Loadable(lazy(() => import('@web/pages/wizards/NewFacilityManagerWizard')));
const NewInsurerManagerWizard = Loadable(lazy(() => import('@web/pages/wizards/NewInsurerManagerWizard')));
const NewMedicalDirectorWizard = Loadable(lazy(() => import('@web/pages/wizards/NewMedicalDirectorWizard')));
const AddExistingFacilityWizard = Loadable(lazy(() => import('@web/pages/wizards/ExistingFacilityWizard') ))
const AddExistingDoctorWizard = Loadable(lazy(() => import('@web/pages/wizards/ExistingDoctorWizard') ))

const MainRoutes = [
  {
    path: '/',
    element: <AuthGuard component={MainLayout} />,
    children: [
      { index: true, element: <Navigate to="/dashboard/default" /> },
      { path: '/dashboard/default', element: <Dashboard /> },
      { path: '/onboarding/facilities', element: <Facilities /> },
      { path: '/onboarding/insurers', element: <Insurers /> },
      { path: '/users/administrators', element: <Administrators /> },
      { path: '/users/facility-managers', element: <FacilityManagers /> },
      { path: '/users/insurance-managers', element: <InsuranceManagers /> },
      { path: '/users/medical-directors', element: <MedicalDirectors /> },
      { path: '/users/doctors', element: <Doctors /> },
      { path: '/users/patients', element: <Patients /> },
      { path: '/activities/appointments', element: <Appointments /> },
      { path: '/activities/services', element: <Services /> },
      { path: '/activities/specialities', element: <Specialities /> },
      { path: '/finances/statements', element: <Statements /> },
      { path: '/finances/claims', element: <Claims /> },
      { path: '/finances/reconciliations', element: <Reconciliations /> },
      { path: '*', element: <SamplePage /> }
    ]
  },
  {
    path: '/',
    element: <AuthGuard component={MinimalLayout} />,
    children: [
      { path: '/wizards/onboarding', element: <OnboardingWizard /> },
      { path: '/wizards/facility', element: <NewFacilityWizard /> },
      { path: '/wizards/doctor', element: <NewDoctorWizard /> },
      { path: '/wizards/patient', element: <NewPatientWizard /> },
      { path: '/wizards/facility-manager', element: <NewFacilityManagerWizard /> },
      { path: '/wizards/insurance-manager', element: <NewInsurerManagerWizard /> },
      { path: '/wizards/medical-director', element: <NewMedicalDirectorWizard /> },
      { path: '/activities/appointment/:appointmentId', element: <Appointment /> },
      { path: '/wizards/add-existing-facility', element: <AddExistingFacilityWizard /> },
      { path: '/wizards/add-existing-doctor', element: <AddExistingDoctorWizard /> }
    ]
  }
];

export default MainRoutes;
