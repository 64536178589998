// assets
import {
  AuditOutlined,
  FundViewOutlined,
  IdcardOutlined,
  ReconciliationOutlined,
  RocketOutlined,
} from '@ant-design/icons'

const menus = {
  id: 'group-users',
  title: 'Users',
  type: 'group',
  children: [
    {
      id: 'administrators',
      title: 'Administrators',
      type: 'item',
      url: '/users/administrators',
      icon: RocketOutlined,
      breadcrumbs: true,
      fab: '/wizards/administrator',
      resource: 'administrators',
    },
    {
      id: 'patients',
      title: 'Patients',
      type: 'item',
      url: '/users/patients',
      icon: AuditOutlined,
      breadcrumbs: true,
      fab: '/wizards/patient',
      resource: 'patients',
    },
    {
      id: 'doctors',
      title: 'Doctors',
      type: 'item',
      url: '/users/doctors',
      icon: IdcardOutlined,
      breadcrumbs: true,
      fab: '/wizards/doctor',
      resource: 'doctors',
    },
    {
      id: 'facility-manager',
      title: 'Facility Managers',
      type: 'item',
      url: '/users/facility-managers',
      icon: ReconciliationOutlined,
      breadcrumbs: true,
      fab: '/wizards/facility-manager',
      resource: 'facility-managers',
    },
    {
      id: 'insurance-manager',
      title: 'Insurance Managers',
      type: 'item',
      url: '/users/insurance-managers',
      icon: ReconciliationOutlined,
      breadcrumbs: true,
      fab: '/wizards/insurance-manager',
      resource: 'insurance-managers',
    },
    {
      id: 'insurance-manager',
      title: 'Medical Directors',
      type: 'item',
      url: '/users/medical-directors',
      icon: FundViewOutlined,
      breadcrumbs: true,
      fab: '/wizards/medical-director',
      resource: 'medical-directors',
    },
  ],
}

export default menus;
