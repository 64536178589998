import { DoctorListItemDto } from '@web/gen/api';
import { api } from '@web/hooks/useApi';
import { call } from '@web/utils/api.utils';
import { DisplayUtils } from '@web/utils/display.utils';
import { action, makeObservable, observable } from 'mobx';


export class DoctorsViewModel {
  constructor() {
    makeObservable(this)
  }

  @observable facilities :DoctorListItemDto[] = []

  @action
  async loadAllFacilities(){
    this.facilities = await call(api.users.existingDoctors);
  }

  async searchDoctors(input: string) {
    if (!this.facilities?.length) await this.loadAllFacilities();
    if (input.length && this.facilities?.length) {
      return this.facilities.filter(u =>
        u.firstName.toLowerCase().includes(input.toLowerCase()) ||
        u.otherNames.toLowerCase().includes(input.toLowerCase()) ||
        u.email.toLowerCase().includes(input.toLowerCase()) ||
        u.specialities.toString().toLowerCase().includes(input.toLowerCase()) ||
        u.lastName.toLowerCase().includes(input.toLowerCase())
      );
    }
    return this.facilities.map(x => ({...x, name: DisplayUtils.fullName(x)}) );
  }
}
