/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateAppointmentDto {
  /** @example "TEXT" */
  mode: 'VIDEO' | 'AUDIO' | 'TEXT';
  symptoms: string[];
  /** @default "2024-07-29T10:15:55.461Z" */
  startsAt: string;
  /** @default "2024-07-29T10:15:55.461Z" */
  completesAt: string;
  /** @example "Slight headache and swelling on the lips" */
  narrative?: string;
  /** An optional facility */
  facilityId?: string;
  /** The id of the selected doctor */
  doctorId?: string;
  offeringId: string;
  cost: number;
}

export interface AppointmentEntity {
  /** UUID */
  id: string;
  narrative: string;
  /** @example "STARTED" */
  status: 'PENDING_PAYMENT' | 'PENDING_ASSIGNMENT' | 'SCHEDULED' | 'STARTED' | 'COMPLETED' | 'CANCELLED';
  mode: 'VIDEO' | 'AUDIO' | 'TEXT';
  /**
   * When the appointment is scheduled to start
   * @format date-time
   */
  startsAt: string;
  /**
   * When the appointment actually started
   * @format date-time
   */
  startedAt?: string;
  /**
   * When the appointment is scheduled to complete
   * @format date-time
   */
  completesAt: string;
  /**
   * When the appointment actually completed
   * @format date-time
   */
  completedAt?: string;
  /**
   * When the appointment was booked
   * @format date-time
   */
  createdAt: string;
  reportId?: string;
}

export interface JoinAppointmentResponseDto {
  id: string;
  mode: 'VIDEO' | 'AUDIO' | 'TEXT';
  reportId: string;
  meetingId: string;
  token: string;
}

export interface RescheduleAppointmentDto {
  /** @default "2024-07-29T10:15:55.461Z" */
  startsAt: string;
  /** @default "2024-07-29T10:15:55.461Z" */
  completesAt: string;
}

export interface ReassignAppointmentDto {
  doctorId: string;
}

export interface FacilityEntity {
  /**
   * UUID
   * @example "c28f1a40-67d4-4e18-8acc-071613c3dea7"
   */
  id: string;
  /** @example "Harmony Health Care" */
  name: string;
  /** Url to facility's logo */
  logo: string;
  /** Subdomain for the facility */
  slug: string;
  /** Registered domain for the facility */
  registeredDomain?: string;
  /** Address for the facility */
  address: string;
  /** Status for the facility */
  status: object;
  /** @format date-time */
  createdAt: string;
}

export interface ReportLabRequestEntity {
  id: string;
  /** @example "SCAN" */
  type: 'SCAN' | 'LAB';
  /**
   * Details of the request
   * @example "Blood test for cholesterol"
   */
  details: string;
  /** File URL */
  results?: string;
}

export interface ReportNotesEntity {
  id: string;
  /**
   * Content of the note
   * @example "Patient seems to be responding well to the treatment"
   */
  content: string;
}

export interface ReportPrescriptionEntity {
  id: string;
  /**
   * Frequency of the medicine intake
   * @example "Twice a day"
   */
  frequency: string;
  /**
   * Name of the medicine
   * @example "Paracetamol"
   */
  medicine: string;
  /**
   * Number of days for which the medicine is prescribed
   * @example 7
   */
  numberOfDays: number;
  /**
   * Route of administration for the medicine
   * @example "Oral"
   */
  routeOfAdministration: string;
}

export interface ReportEntity {
  id: string;
  /**
   * Diagnosis of the report
   * @example "Hypertension"
   */
  diagnosis: string[];
  /** Array of lab requests */
  labRequests: ReportLabRequestEntity[];
  /** Array of notes */
  notes: ReportNotesEntity[];
  /** Array of prescriptions */
  prescriptions: ReportPrescriptionEntity[];
}

export interface User {
  /** @example "c28f1a40-67d4-4e18-8acc-071613c3dea7" */
  id: string;
  /** Whether or not user is onboarded */
  onboarded: boolean;
  /**
   * Defaults to UNKNOWN when user has not completed onboarding
   * @example "PATIENT"
   */
  type:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
  /** @example "email@example.com" */
  email: string;
  /** @example "Mr." */
  title: string;
  /** @example "Isaac" */
  firstName: string;
  /** @example "Mitchel" */
  lastName: string;
  /** @example "" */
  otherNames: string;
  /** @example "https://loremflickr.com/320/240/profile" */
  profilePicture: string;
  /** @example "PENDING" */
  status: 'PENDING' | 'APPROVED' | 'DECLINED' | 'OFFBOARDED';
  /** @example "" */
  gender: string;
  /** @example "" */
  address: string;
  /** @format date-time */
  createdAt: string;
}

export interface AppointmentDetailEntity {
  id: string;
  mode: 'VIDEO' | 'AUDIO' | 'TEXT';
  symptoms: string[];
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  completesAt: string;
  /** @format date-time */
  completedAt?: string;
  narrative: string;
  cost: number;
  facility: FacilityEntity;
  report?: ReportEntity;
  doctor?: User;
  patient: User;
  /** @example "STARTED" */
  status: 'PENDING_PAYMENT' | 'PENDING_ASSIGNMENT' | 'SCHEDULED' | 'STARTED' | 'COMPLETED' | 'CANCELLED';
}

export interface AvailabilityEntity {
  /** UUID */
  id: string;
  /** Day of the week with zero being Sunday and six being Saturday  */
  day: number;
  startTime: string;
  endTime: string;
}

export interface AvailabilityDto {
  /** Day of the week with zero being Sunday and six being Saturday  */
  day: number;
  startTime: string;
  endTime: string;
}

export interface CreateAvailabilityDto {
  availabilities: AvailabilityDto[];
}

export interface ContactDto {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface Domain {
  slug: string;
  name: string;
}

export interface Files {
  logo: string;
  documents: string[];
}

export interface Theme {
  font: string;
  color: string;
}

export interface OfferingDto {
  offeringId: string;
  cost: number;
}

export interface CreateFacilityDto {
  name: string;
  email: string;
  address: string;
  digitalAddress: string;
  contact: ContactDto;
  domain: Domain;
  files: Files;
  theme: Theme;
  managers: string[];
  availabilities: AvailabilityDto[];
  consultationCost: number;
  offerings: OfferingDto[];
}

export interface SystemPayload {
  /** UUID */
  title: string;
  body: string;
}

export interface AppointmentPayload {
  /** UUID */
  appointmentId: string;
  /** @format date-time */
  startsAt: string;
}

export interface PaymentPayload {
  /** UUID */
  paymentId: string;
  amount: number;
  narrative: string;
}

export interface UserPayload {
  /** UUID */
  userId: string;
  userType:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
}

export interface NotificationEntity {
  /** UUID */
  id: string;
  payload: SystemPayload | AppointmentPayload | PaymentPayload | UserPayload;
  /** @example "LOW" */
  priority: 'LOW' | 'MEDIUM' | 'HIGH';
  /** @example "SYSTEM" */
  type:
    | 'SYSTEM'
    | 'APPOINTMENT_DUE'
    | 'APPOINTMENT_JOINED'
    | 'APPOINTMENT_PAID'
    | 'APPOINTMENT_CANCELLED'
    | 'APPOINTMENT_REASSIGNED'
    | 'USER_INVITED'
    | 'USER_ONBOARDED'
    | 'USER_OFFBOARDED'
    | 'PAYMENT_DISBURSED';
  /** @format date-time */
  readAt: string;
  /** @format date-time */
  createdAt: string;
}

export interface UpdateTokenDto {
  token: string;
  platform: string;
  type: string;
}

export interface OfferingItemDto {
  doctorId?: string;
  facilityId?: string;
  offeringId: string;
  cost: number;
}

export interface AddOfferingCostDto {
  offerings?: OfferingItemDto[];
}

export interface OfferingEntity {
  /** UUID */
  id: string;
  /** Name of the offering */
  name: string;
  /** Description of the offering */
  description: string;
  /** Type of the offering */
  type: object;
}

export interface OfferingCostEntity {
  /** UUID */
  id: string;
  /** Name of the offering */
  name: string;
  /** Description of the offering */
  description: string;
  /** Cost of the offering */
  cost: number;
  /** Type of the offering */
  type: object;
  /**
   * Type of the offering
   * @format date-time
   */
  createdAt: string;
}

export interface PaymentEntity {
  /** UUID */
  id: string;
  narration?: string;
  amount: number;
  /** @example "APPOINTMENT" */
  source: 'APPOINTMENT' | 'SUBSCRIPTION' | 'INSURANCE_CLAIM' | 'DISBURSEMENT';
  /** @example "NOT_STARTED" */
  status: 'NOT_STARTED' | 'INITIATED' | 'FAILED' | 'COMPLETED' | 'CANCELLED';
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface PaymentDetailsDto {
  /** UUID */
  id: string;
  narration?: string;
  amount: number;
  /** @example "APPOINTMENT" */
  source: 'APPOINTMENT' | 'SUBSCRIPTION' | 'INSURANCE_CLAIM' | 'DISBURSEMENT';
  /** @example "NOT_STARTED" */
  status: 'NOT_STARTED' | 'INITIATED' | 'FAILED' | 'COMPLETED' | 'CANCELLED';
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  subPayments?: PaymentEntity[];
}

export interface CreateInsurancePaymentDto {
  appointmentId: string;
  insurerId: string;
  amount: number;
}

export interface UpdateReportDto {
  id?: string;
  /**
   * Diagnosis of the report
   * @example "Hypertension"
   */
  diagnosis?: string[];
  /** Array of lab requests */
  labRequests?: ReportLabRequestEntity[];
  /** Array of notes */
  notes?: ReportNotesEntity[];
  /** Array of prescriptions */
  prescriptions?: ReportPrescriptionEntity[];
}

export interface IcdEntity {
  id: string;
  title: string;
  tag?: string;
}

export interface InsuranceEntity {
  /**
   * UUID
   * @example "c28f1a40-67d4-4e18-8acc-071613c3dea7"
   */
  id: string;
  /** @example "Harmony Insurance & Co" */
  name: string;
  /** Url to insurer's logo */
  logo: string;
  /** Subdomain for the facility */
  slug: string;
  /** Address for the insurance company */
  address: string;
  /** @format date-time */
  createdAt: string;
}

export interface UserContactEntity {
  contactNumber: string;
  email: string;
}

export interface UserProfileEntity {
  /** @example "Mr." */
  title: string;
  firstName: string;
  lastName: string;
  otherNames?: string;
  gender?: string;
  city?: string;
  country?: string;
  address?: string;
  digitalAddress?: string;
  profilePicture?: string;
  dateOfBirth?: string;
  primaryContact: UserContactEntity;
  emergencyContact?: UserContactEntity;
}

export interface TenantEntity {
  id?: string;
  color: string;
  favicon: string;
  logo: string;
  name: string;
}

export interface AuthenticatedUserEntity {
  /** @example "c28f1a40-67d4-4e18-8acc-071613c3dea7" */
  id: string;
  /**
   * Defaults to UNKNOWN when user has not completed onboarding
   * @example "PATIENT"
   */
  userType:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
  /** @example "email@example.com" */
  email: string;
  /** Whether or not user is onboarded */
  onboarded: boolean;
  /** @default "PENDING" */
  status: 'PENDING' | 'APPROVED' | 'DECLINED' | 'OFFBOARDED';
  /** @example ["read:appointments","create:appointments","read:reports"] */
  permissions: string[];
  profile?: UserProfileEntity;
  facilities?: FacilityEntity[];
  tenant?: TenantEntity;
  /**
   * Defaults to userType when user role is not composite
   * @example "PATIENT"
   */
  assumedRole:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
  /**
   * Only applicable when user role is composite
   * @default []
   */
  compositeRoles: (
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT'
  )[];
}

export interface CreateDoctorDto {
  /** @example "Mr." */
  title: string;
  firstName: string;
  lastName: string;
  otherNames?: string;
  gender?: string;
  city?: string;
  country?: string;
  address: string;
  digitalAddress?: string;
  profilePicture?: string;
  dateOfBirth?: string;
  primaryContact: UserContactEntity;
  emergencyContact?: UserContactEntity;
  availabilities: AvailabilityDto[];
  consultationCost: number;
  offerings: OfferingDto[];
}

export interface CreatePatientDto {
  /** @example "Mr." */
  title: string;
  firstName: string;
  lastName: string;
  otherNames?: string;
  gender?: string;
  city?: string;
  country?: string;
  address?: string;
  digitalAddress?: string;
  profilePicture?: string;
  dateOfBirth?: string;
  primaryContact: UserContactEntity;
  emergencyContact?: UserContactEntity;
}

export interface InviteUserDto {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  /** @example "PATIENT" */
  userType:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
}

export interface ImportUserDto {
  firstName: string;
  lastName: string;
  otherNames: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  phoneNumber: string;
  insuranceNumber: string;
  expiresAt: string;
  createdAt: string;
  /** @example "PATIENT" */
  userType:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
}

export interface DoctorListItemDto {
  /** @example "c28f1a40-67d4-4e18-8acc-071613c3dea7" */
  id: string;
  /** Whether or not user is onboarded */
  onboarded: boolean;
  /**
   * Defaults to UNKNOWN when user has not completed onboarding
   * @example "PATIENT"
   */
  type:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
  /** @example "email@example.com" */
  email: string;
  /** @example "Mr." */
  title: string;
  /** @example "Isaac" */
  firstName: string;
  /** @example "Mitchel" */
  lastName: string;
  /** @example "" */
  otherNames: string;
  /** @example "https://loremflickr.com/320/240/profile" */
  profilePicture: string;
  /** @example "PENDING" */
  status: 'PENDING' | 'APPROVED' | 'DECLINED' | 'OFFBOARDED';
  /** @example "" */
  gender: string;
  /** @example "" */
  address: string;
  /** @format date-time */
  createdAt: string;
  specialities: OfferingCostEntity[];
}

export interface UserDetailsDto {
  /** @example "c28f1a40-67d4-4e18-8acc-071613c3dea7" */
  id: string;
  /** Whether or not user is onboarded */
  onboarded: boolean;
  /**
   * Defaults to UNKNOWN when user has not completed onboarding
   * @example "PATIENT"
   */
  type:
    | 'UNKNOWN'
    | 'ADMINISTRATOR'
    | 'FACILITY_MANAGER'
    | 'INSURANCE_MANAGER'
    | 'MEDICAL_DIRECTOR'
    | 'DOCTOR'
    | 'PATIENT';
  /** @example "email@example.com" */
  email: string;
  /** @example "Mr." */
  title: string;
  /** @example "Isaac" */
  firstName: string;
  /** @example "Mitchel" */
  lastName: string;
  /** @example "" */
  otherNames: string;
  /** @example "https://loremflickr.com/320/240/profile" */
  profilePicture: string;
  /** @example "PENDING" */
  status: 'PENDING' | 'APPROVED' | 'DECLINED' | 'OFFBOARDED';
  /** @example "" */
  gender: string;
  /** @example "" */
  address: string;
  /** @format date-time */
  createdAt: string;
  services: OfferingCostEntity[];
  speciality: OfferingCostEntity[];
  availabilities: AvailabilityEntity[];
}

export type PaystackTransferDto = object;

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/api' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Opticash Dashboard
 * @version 1.0
 * @baseUrl /api
 * @contact
 *
 * The backend API for opticash dashboard
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  appointments = {
    /**
     * No description
     *
     * @tags Appointment
     * @name Create
     * @request POST:/appointments
     * @response `200` `AppointmentEntity` Appointment created successfully.
     */
    create: (data: CreateAppointmentDto, params: RequestParams = {}) =>
      this.request<AppointmentEntity, any>({
        path: `/appointments`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Appointments
     * @request GET:/appointments
     * @response `200` `(AppointmentEntity)[]`
     */
    appointments: (
      query?: {
        hasPrescriptions?: string;
        hasLabRequest?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AppointmentEntity[], any>({
        path: `/appointments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name PatientAppointments
     * @request GET:/appointments/patient/{id}
     * @response `200` `(AppointmentEntity)[]`
     */
    patientAppointments: (id: string, params: RequestParams = {}) =>
      this.request<AppointmentEntity[], any>({
        path: `/appointments/patient/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Join
     * @request POST:/appointments/{id}/join
     * @response `200` `JoinAppointmentResponseDto`
     * @response `404` `any` Appointment not found
     */
    join: (id: string, params: RequestParams = {}) =>
      this.request<JoinAppointmentResponseDto, any>({
        path: `/appointments/${id}/join`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Leave
     * @request POST:/appointments/{id}/leave
     * @response `204` `any` Session closed successfully.
     * @response `404` `any` Appointment not found
     * @response `410` `any` Appointment already completed
     */
    leave: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/appointments/${id}/leave`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Reschedule
     * @request POST:/appointments/{id}/reschedule
     * @response `204` `any` Rescheduled successfully.
     * @response `404` `any` Appointment not found
     * @response `410` `any` Appointment already completed
     */
    reschedule: (id: string, data: RescheduleAppointmentDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/appointments/${id}/reschedule`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Reassign
     * @request POST:/appointments/{id}/reassign
     * @response `204` `any` Reassigned successfully.
     * @response `404` `any` Appointment not found
     */
    reassign: (id: string, data: ReassignAppointmentDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/appointments/${id}/reassign`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Appointment
     * @name Details
     * @request GET:/appointments/{id}
     * @response `200` `AppointmentDetailEntity`
     * @response `404` `any` Appointment not found
     */
    details: (id: string, params: RequestParams = {}) =>
      this.request<AppointmentDetailEntity, any>({
        path: `/appointments/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  availabilities = {
    /**
     * No description
     *
     * @tags Availabilities
     * @name Doctor
     * @request GET:/availabilities/doctor/{id}
     * @response `200` `(AvailabilityEntity)[]`
     */
    doctor: (id: string, params: RequestParams = {}) =>
      this.request<AvailabilityEntity[], any>({
        path: `/availabilities/doctor/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availabilities
     * @name Facility
     * @request GET:/availabilities/facility/{id}
     * @response `200` `(AvailabilityEntity)[]`
     */
    facility: (id: string, params: RequestParams = {}) =>
      this.request<AvailabilityEntity[], any>({
        path: `/availabilities/facility/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availabilities
     * @name Create
     * @request POST:/availabilities
     * @response `204` `any`
     */
    create: (data: CreateAvailabilityDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/availabilities`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  facilities = {
    /**
     * No description
     *
     * @tags Facility
     * @name Create
     * @request POST:/facilities
     * @response `201` `any` Facility created successfully.
     */
    create: (data: CreateFacilityDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/facilities`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility
     * @name List
     * @request GET:/facilities
     * @response `200` `(FacilityEntity)[]`
     */
    list: (params: RequestParams = {}) =>
      this.request<FacilityEntity[], any>({
        path: `/facilities`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility
     * @name Existing
     * @request GET:/facilities/existing
     * @response `200` `(FacilityEntity)[]`
     */
    existing: (params: RequestParams = {}) =>
      this.request<FacilityEntity[], any>({
        path: `/facilities/existing`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility
     * @name FindOne
     * @request GET:/facilities/{id}
     * @response `200` `any`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/facilities/${id}`,
        method: 'GET',
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Notifications
     * @name List
     * @request GET:/notifications
     * @response `200` `(NotificationEntity)[]`
     */
    list: (params: RequestParams = {}) =>
      this.request<NotificationEntity[], any>({
        path: `/notifications`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name UpdateToken
     * @request PUT:/notifications/token
     * @response `204` `any` Successfully updated
     */
    updateToken: (data: UpdateTokenDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/notifications/token`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name Notify
     * @request POST:/notifications/{userId}/notify
     * @response `204` `any` Successfully notified
     */
    notify: (userId: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/notifications/${userId}/notify`,
        method: 'POST',
        ...params,
      }),
  };
  offerings = {
    /**
     * No description
     *
     * @tags Offerings
     * @name Create
     * @request POST:/offerings/cost
     * @response `201` `any`
     */
    create: (data: AddOfferingCostDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/offerings/cost`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offerings
     * @name Services
     * @request GET:/offerings/services
     * @response `200` `(OfferingEntity)[]`
     */
    services: (params: RequestParams = {}) =>
      this.request<OfferingEntity[], any>({
        path: `/offerings/services`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offerings
     * @name Specialities
     * @request GET:/offerings/specialities
     * @response `200` `(OfferingEntity)[]`
     */
    specialities: (params: RequestParams = {}) =>
      this.request<OfferingEntity[], any>({
        path: `/offerings/specialities`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offerings
     * @name Doctor
     * @request GET:/offerings/doctor/{id}
     * @response `200` `(OfferingCostEntity)[]`
     */
    doctor: (
      id: string,
      query: {
        type: 'SERVICE' | 'SPECIALITY';
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferingCostEntity[], any>({
        path: `/offerings/doctor/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offerings
     * @name Facility
     * @request GET:/offerings/facility/{id}
     * @response `200` `(OfferingCostEntity)[]`
     */
    facility: (
      id: string,
      query?: {
        type?: 'SERVICE' | 'SPECIALITY';
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferingCostEntity[], any>({
        path: `/offerings/facility/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offerings
     * @name Remove
     * @request DELETE:/offerings/{id}
     * @response `204` `(OfferingCostEntity)[]`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<OfferingCostEntity[], any>({
        path: `/offerings/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  };
  payments = {
    /**
     * No description
     *
     * @tags Payment
     * @name List
     * @request GET:/payments
     * @response `200` `(PaymentEntity)[]`
     */
    list: (params: RequestParams = {}) =>
      this.request<PaymentEntity[], any>({
        path: `/payments`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name Details
     * @request GET:/payments/{id}
     * @response `200` `PaymentDetailsDto`
     */
    details: (id: string, params: RequestParams = {}) =>
      this.request<PaymentDetailsDto, any>({
        path: `/payments/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name CreateInsurancePayment
     * @request POST:/payments/insurance
     * @response `201` `any`
     */
    createInsurancePayment: (data: CreateInsurancePaymentDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/payments/insurance`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  reports = {
    /**
     * No description
     *
     * @tags Report
     * @name FindOne
     * @request GET:/reports/{id}
     * @response `200` `any`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/reports/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name Update
     * @request PATCH:/reports/{id}
     * @response `204` `any` Report successfully updated
     */
    update: (id: string, data: UpdateReportDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/reports/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name LabRequests
     * @request GET:/reports/lab-requests
     * @response `200` `(ReportLabRequestEntity)[]`
     */
    labRequests: (params: RequestParams = {}) =>
      this.request<ReportLabRequestEntity[], any>({
        path: `/reports/lab-requests`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name Prescriptions
     * @request GET:/reports/prescriptions
     * @response `200` `(ReportPrescriptionEntity)[]`
     */
    prescriptions: (params: RequestParams = {}) =>
      this.request<ReportPrescriptionEntity[], any>({
        path: `/reports/prescriptions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name Download
     * @request GET:/reports/download/{id}/{type}
     * @response `200` `string`
     */
    download: (id: string, type: 'PRESCRIPTION' | 'LAB_REQUEST', params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/reports/download/${id}/${type}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name PastNotes
     * @request GET:/reports/patients/{id}/notes
     * @response `200` `(string)[]`
     */
    pastNotes: (id: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/reports/patients/${id}/notes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name SearchIcd
     * @request GET:/reports/icd/search
     * @response `200` `(IcdEntity)[]`
     */
    searchIcd: (
      query: {
        /**
         * Search query
         * @example "cholera"
         */
        q: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IcdEntity[], any>({
        path: `/reports/icd/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  insurers = {
    /**
     * No description
     *
     * @tags Insurers
     * @name List
     * @request GET:/insurers
     * @response `200` `(InsuranceEntity)[]`
     */
    list: (params: RequestParams = {}) =>
      this.request<InsuranceEntity[], any>({
        path: `/insurers`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  uploads = {
    /**
     * No description
     *
     * @tags Uploads
     * @name Profile
     * @request POST:/uploads/profile
     * @response `204` `any` Profile uploaded successfully
     */
    profile: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/uploads/profile`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Uploads
     * @name Documents
     * @request POST:/uploads/documents
     * @response `201` `any`
     */
    documents: (
      data: {
        /** @minItems 1 */
        files: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/uploads/documents`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Uploads
     * @name LabReports
     * @request POST:/uploads/lab-reports/{id}
     * @response `201` `any`
     */
    labReports: (
      id: string,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/uploads/lab-reports/${id}`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags User
     * @name Me
     * @request GET:/users/me
     * @response `200` `AuthenticatedUserEntity`
     */
    me: (params: RequestParams = {}) =>
      this.request<AuthenticatedUserEntity, any>({
        path: `/users/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Stats
     * @request GET:/users/stats
     * @response `200` `(object)[]`
     */
    stats: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/users/stats`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CreateDoctor
     * @request POST:/users/doctor
     * @response `201` `any`
     */
    createDoctor: (data: CreateDoctorDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/doctor`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CreatePatient
     * @request POST:/users/patient
     * @response `201` `any`
     */
    createPatient: (data: CreatePatientDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/patient`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name OnboardFacilityManager
     * @request POST:/users/facility-manager
     * @response `201` `any`
     */
    onboardFacilityManager: (data: CreatePatientDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/facility-manager`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name OnboardMedicalDirector
     * @request POST:/users/medical-director
     * @response `201` `any`
     */
    onboardMedicalDirector: (data: CreatePatientDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/medical-director`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name OnboardInsuranceManager
     * @request POST:/users/insurance-manager
     * @response `201` `any`
     */
    onboardInsuranceManager: (data: CreatePatientDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/insurance-manager`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Invite
     * @request POST:/users/invite
     * @response `204` `any`
     */
    invite: (data: InviteUserDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/invite`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Import
     * @request POST:/users/import
     * @response `204` `any`
     */
    import: (data: ImportUserDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/import`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name AddExisting
     * @request POST:/users/invite-existing
     * @response `204` `any`
     */
    addExisting: (data: string[], params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/invite-existing`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SearchUsers
     * @request GET:/users/search
     * @response `200` `(User)[]`
     */
    searchUsers: (
      query: {
        /**
         * Search by name
         * @example "Isaac"
         */
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<User[], any>({
        path: `/users/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListPatients
     * @request GET:/users/patients
     * @response `200` `(User)[]`
     */
    listPatients: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users/patients`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListDoctors
     * @request GET:/users/doctors
     * @response `200` `(DoctorListItemDto)[]`
     */
    listDoctors: (params: RequestParams = {}) =>
      this.request<DoctorListItemDto[], any>({
        path: `/users/doctors`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ExistingDoctors
     * @request GET:/users/existing-doctors
     * @response `200` `(DoctorListItemDto)[]`
     */
    existingDoctors: (params: RequestParams = {}) =>
      this.request<DoctorListItemDto[], any>({
        path: `/users/existing-doctors`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListFacilityManagers
     * @request GET:/users/facility-managers
     * @response `200` `(User)[]`
     */
    listFacilityManagers: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users/facility-managers`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListMedicalDirectors
     * @request GET:/users/medical-directors
     * @response `200` `(User)[]`
     */
    listMedicalDirectors: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users/medical-directors`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListInsuranceManagers
     * @request GET:/users/insurance-managers
     * @response `200` `(User)[]`
     */
    listInsuranceManagers: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users/insurance-managers`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ListAdministrators
     * @request GET:/users/administrators
     * @response `200` `(User)[]`
     */
    listAdministrators: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users/administrators`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Details
     * @request GET:/users/{id}
     * @response `200` `UserDetailsDto`
     */
    details: (id: string, params: RequestParams = {}) =>
      this.request<UserDetailsDto, any>({
        path: `/users/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Remove
     * @request DELETE:/users/{id}
     * @response `200` `any`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name Paystack
     * @request POST:/webhooks/paystack
     * @response `204` `any`
     * @response `403` `any`
     */
    paystack: (data: PaystackTransferDto, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/webhooks/paystack`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
